import { API_URL } from '../constants';
import { getCookie } from './cookiesControls';

function getUrlWithBase(url: string) {
  return `${API_URL}/${url}`;
}

const defaultHeaders = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const apiClient = {
  async get<ResponseType>(url: string, params?: RequestInit) {
    let response = await fetch(getUrlWithBase(url), {
      method: 'GET',
      ...defaultHeaders,
      ...params,
    });

    if (response.status === 404) throw new Error('404 Not Found');

    return await response.json() as Promise<ResponseType>;
  },
  async getResponse<ResponseType>(url: string, params?: RequestInit) {
    let response = await fetch(getUrlWithBase(url), {
      method: 'GET',
      ...defaultHeaders,
      ...params,
    });
    const isJson = response.headers.get('content-type')?.startsWith('application/json');

    return { status: response.status, response: (isJson ? await response.json() : response) } as { status: number, response: ResponseType };
  },
  async post<ResponseType>(url: string, params?: RequestInit) {
    const cookieReferer = getCookie('referer') || '';

    let response = await fetch(getUrlWithBase(url), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Original-Referer': cookieReferer,
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer-when-downgrade',
      ...params,
      // ...(params.body ? { body: JSON.stringify(params.body) } : {}),
    });
    return await response.json() as Promise<ResponseType>;
  },
  async postResponse<ResponseType>(url: string, params?: RequestInit) {
    const cookieReferer = getCookie('referer') || '';

    let response = await fetch(getUrlWithBase(url), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Original-Referer': cookieReferer,
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer-when-downgrade',
      ...params,
    });
    return { status: response.status, response: await response.json() } as { status: number, response: ResponseType };
  },
};

export default apiClient;
