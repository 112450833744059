import classnames from 'classnames';
import { useAtomValue } from 'jotai';

import { theme } from '../store/theme';

import styles from './Ellipse.module.scss';
import Img from './Img';

export enum EllipseVariants {
  DRAW = 'draw',
  BORDER = 'border',
  NOISE = 'noise',
  GRADIENT = 'gradient',
}

type EllipseProps = {
  className?: string,
  size: number,
  variant: EllipseVariants,
};

const Ellipse = ({ className, size, variant }: EllipseProps) => {
  const appTheme = useAtomValue(theme);
  const Images = {
    [EllipseVariants.DRAW]: `/media/ellipses/ellipse-draw-${appTheme}.svg`,
    [EllipseVariants.BORDER]: '/media/ellipses/ellipse-border.svg',
    [EllipseVariants.NOISE]: `/media/ellipses/elps_grain_${appTheme}.webp`,
    [EllipseVariants.GRADIENT]: `/media/ellipses/ellipse-gradient-${appTheme}.svg`,
  };

  return (
    <Img
      src={Images[variant]}
      alt='ellipse'
      width={size}
      height={size}
      className={classnames(className, {
        [styles.ellipseBorder]: variant === EllipseVariants.BORDER,
      })}
    />
  );
};

export default Ellipse;
